import React from 'react';
import OrganizationList from '../logic/endpoints/organizationList'
const OrgList: React.FC = () => {
  
  const requestDataProps = { requestDataType: '' };
  function isOrgList(props: any): props is { props: { children: Event[] } } {
    return props && props.props && Array.isArray(props.props.children);
  }
  const orgData = OrganizationList(requestDataProps);
  const requestOrgListProps = isOrgList(orgData)
    ? orgData.props.children
    : [];
  console.log(requestOrgListProps)
  return (
    <div>Org List</div>
  );
}

export default OrgList