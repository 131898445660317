import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { fetchBoxScores } from '../logic/endpoints/boxScores';
import { siteUrl } from '../utils/siteUrl';

interface RequestDataProps {
  year: string;
  getTeamId: number;
  getGameId: number;
  open: boolean;
  onClose: () => void;
}

interface PlayerStats {
  playerName: string;
  points: number;
  rebounds: number;
  assists: number;
  blocks: number;
  steals: number;
  threePoints: number;
  playerUrl: string;
}

interface BoxScore {
  event_name: string;
  event_id: string;
  team_name: string;
  team_logo: string;
  org_nickname: string;
  game_id: string;
  gm_r_label: string;
  game_result: string;
  opp_logo: string;
  opp_name: string;
  opp_nickname: string;
  player_stat: Array<any>;
}

const BoxScores: React.FC<RequestDataProps> = ({ year, getTeamId, getGameId, open, onClose }) => {
  const [boxScoreData, setBoxScoreData] = useState<BoxScore | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      setBoxScoreData(null);
      setErrorMessage(null);
      setIsLoading(true);

      const fetchData = async () => {
        try {
          const teamBoxScoreData = {
            year: year,
            gameId: getGameId,
            teamId: getTeamId,
          };

          const result = await fetchBoxScores(teamBoxScoreData);

          if (result && result.length > 0 && result[0].box_score) {
            const parsedResult: BoxScore = JSON.parse(result[0].box_score);
            setBoxScoreData(parsedResult);
          } else {
            setErrorMessage('Boxscore is not available.');
          }
        } catch (error) {
          setErrorMessage('Error fetching box scores.');
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [year, getTeamId, getGameId, open]);

  const removeDot = (input: string): string => {
    return input.replace(/\./g, '');
  };
  
  // Function to calculate the total stats for the given players
  const calculateTotalStats = (players: any[] | undefined) => {
    if (!players || players.length === 0) return { pts: 0, rbs: 0, ast: 0, blk: 0, stl: 0, threePt: 0 };

    return players.reduce(
      (totals, playerData) => {
        const stats = playerData.player_info.stats;
        return {
          pts: totals.pts + stats.pts,
          rbs: totals.rbs + stats.rbs,
          ast: totals.ast + stats.ast,
          blk: totals.blk + stats.blk,
          stl: totals.stl + stats.stl,
          threePt: totals.threePt + stats.three_pt,
        };
      },
      { pts: 0, rbs: 0, ast: 0, blk: 0, stl: 0, threePt: 0 }
    );
  };

  const renderPlayerStats = (players: any[] | undefined) => {
    if (!players || players.length === 0) {
      return <div>No player data available.</div>;
    }

    return (
      <table className="text-left ghost-white-bg small-margin-bottom">
        <thead>
          <tr>
            <th>NAME</th><th>PTS</th><th>REB</th><th>AST</th><th>BLK</th><th>STL</th><th>3PT</th>
          </tr>
        </thead>
        <tbody>
          {players.map((playerData) => {
            const player = playerData.player_info;
            return (
              <tr className="color-body emphasis" key={player.player_id}>
                <td>
                  <a href={`${siteUrl(window.location.href, 'getSiteUrl')}/player/${player.player_nickname}/stats/${boxScoreData?.event_id}/${year}/tournament/`} target="_blank" rel="noopener noreferrer">
                    {player.player_name}
                  </a>
                </td>
                <td>{player.stats.pts}</td>
                <td>{player.stats.rbs}</td>
                <td>{player.stats.ast}</td>
                <td>{player.stats.blk}</td>
                <td>{player.stats.stl}</td>
                <td>{player.stats.three_pt}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogActions style={{ justifyContent: 'flex-start' }}>
        <Button onClick={onClose} color="primary" style={{ position: 'absolute', left: '10px', top: '10px' }}>
          Close
        </Button>
      </DialogActions>
      <DialogTitle className="text-center">
        {boxScoreData?.event_name}
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <div>Loading...</div>
        ) : errorMessage ? (
          <div>{errorMessage}</div>
        ) : (
          <>
          <h2 className="text-center">{boxScoreData?.game_result}</h2>
          <div className="cts_box_score small-12 medium-12 large-12 text-white" style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* First team block */}
            <div className="team-block small-12 medium-6 large-6" style={{ padding: '10px' }}>
              <div className="team-logo flex justify-center items-center">
                <a className="logo_box flex align-center" href={`${siteUrl(window.location.href, 'getSiteUrl')}/club/${boxScoreData?.org_nickname}/teams`} target="_blank">
                  <img
                    alt={boxScoreData?.team_name}
                    title={boxScoreData?.team_name}
                    src={
                      boxScoreData?.team_logo
                        ? `${siteUrl(window.location.href, 'getSiteUrl')}/wp-content/uploads/org-logos/${boxScoreData.team_logo}`
                        : `${siteUrl(window.location.href, 'getSiteUrl')}/wp-content/uploads/org-logos/g365_blank-placeholder_400x300.png`
                    }
                    style={{ width: '133px', height: '100px' }}
                  />
                </a>
              </div>
              <div className="team-name flex items-center justify-center text-black">
                <span className="ts_top flex align-center">{removeDot(boxScoreData?.team_name ?? '')}</span>
              </div>

              {/* Total stats for the first team */}
              <div className="team-total-stats">
                {(() => {
                  const totalStats = calculateTotalStats(boxScoreData?.player_stat?.[0]?.[0]?.pl_data);
                  return (
                    <table className="text-left" style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th>PTS</th><th>REB</th><th>AST</th><th>BLK</th><th>STL</th><th>3PT</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{totalStats.pts}</td>
                          <td>{totalStats.rbs}</td>
                          <td>{totalStats.ast}</td>
                          <td>{totalStats.blk}</td>
                          <td>{totalStats.stl}</td>
                          <td>{totalStats.threePt}</td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })()}
              </div>

              <div className="player-stats large-margin-bottom table-scroll">
                {renderPlayerStats(boxScoreData?.player_stat?.[0]?.[0]?.pl_data)}
              </div>
            </div>

            {/* Opponent team block */}
            <div className="team-block small-12 medium-6 large-6" style={{ padding: '10px' }}>
              <div className="team-logo flex justify-center items-center">
                <a className="logo_box" href={`${siteUrl(window.location.href, 'getSiteUrl')}/club/${boxScoreData?.opp_nickname}/teams`} target="_blank">
                  <img
                    alt={boxScoreData?.opp_name}
                    title={boxScoreData?.opp_name}
                    src={
                      boxScoreData?.opp_logo
                        ? `${siteUrl(window.location.href, 'getSiteUrl')}/wp-content/uploads/org-logos/${boxScoreData.opp_logo}`
                        : `${siteUrl(window.location.href, 'getSiteUrl')}/wp-content/uploads/org-logos/g365_blank-placeholder_400x300.png`
                    }
                    style={{ width: '133px', height: '100px' }}
                  />
                </a>
              </div>
              <div className="team-name flex items-center justify-center text-black">
                <span className="ts_top">{removeDot(boxScoreData?.opp_name ?? '')}</span>
              </div>

              {/* Total stats for the opponent */}
              <div className="team-total-stats ">
                {(() => {
                  const totalStats = calculateTotalStats(boxScoreData?.player_stat?.[0]?.[1]?.opp_data);
                  return (
                    <table className="text-left" style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th>PTS</th><th>REB</th><th>AST</th><th>BLK</th><th>STL</th><th>3PT</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{totalStats.pts}</td>
                          <td>{totalStats.rbs}</td>
                          <td>{totalStats.ast}</td>
                          <td>{totalStats.blk}</td>
                          <td>{totalStats.stl}</td>
                          <td>{totalStats.threePt}</td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })()}
              </div>

              <div className="player-stats large-margin-bottom table-scroll">
                {renderPlayerStats(boxScoreData?.player_stat?.[0]?.[1]?.opp_data)}
              </div>
            </div>
          </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default BoxScores;