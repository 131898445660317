import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import client from '../apolloClient';

const SIGN_IN_MUTATION = gql`
  mutation SignIn($idToken: String!) {
    signIn(idToken: $idToken)
  }
`;

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [signInMutation] = useMutation(SIGN_IN_MUTATION, {
    client,
    onError: (error) => {
      console.error("Error signing in:", error);
      setError("Invalid email or password. Please try again.");
    },
    onCompleted: (data) => {
      // Store the token locally (e.g., localStorage)
      const { signIn: token } = data;
      localStorage.setItem('token', token);
      navigate('/tournament-manager');
    }
  });

  useEffect(() => {
    // Check if a token is already stored in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/tournament-manager');
    }
  }, [navigate]);

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user) {
        const idToken = await user.getIdToken();

        // Send the ID token to the server to get a JWT token via GraphQL
        await signInMutation({ variables: { idToken } });
      }
    } catch (error) {
      console.error("Error signing in: ", error);
      setError("Invalid email or password. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-black">
      <div className="bg-gray-800 rounded-lg shadow-lg p-8 w-full max-w-7xl flex flex-col lg:flex-row items-center lg:items-start">
        {/* Sign-In Form */}
        <div className="w-full lg:w-1/2 p-6">
          <form className="space-y-6" onSubmit={handleSignIn}>
            <div>
              <label htmlFor="email" className="block text-lg font-medium text-gray-300 mb-4">Email</label>
              <input 
                id="email"
                className="block w-full border border-gray-600 rounded-md p-5 bg-gray-700 text-white focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg"
                placeholder="example@test.com"
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-lg font-medium text-gray-300 mb-4">Password</label>    
              <input 
                id="password"
                className="block w-full border border-gray-600 rounded-md p-5 bg-gray-700 text-white focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg"
                placeholder="******"
                type={showPassword ? "text" : "password"} 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
              />
              <div className="mt-4">
                <label htmlFor="showPassword" className="text-gray-300 text-lg">
                  <input 
                    id="showPassword"
                    type="checkbox" 
                    checked={showPassword} 
                    onChange={() => setShowPassword(!showPassword)} 
                    className="mr-2"
                  />
                  Show Password
                </label>
              </div>
            </div>
            <button 
              className="w-full py-5 px-8 bg-indigo-600 text-white font-semibold rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-200"
              type="submit"
            >
              Sign In
            </button>
          </form>
          {error && <p className="mt-8 text-red-500 text-lg text-center">{error}</p>}
        </div>

        {/* Image */}
        <div className="w-full lg:w-1/2 mt-10 lg:mt-0 lg:ml-8 flex justify-center">
          <img 
            className="rounded-lg w-full h-auto max-w-full"
            src="https://opengympremier.com/wp-content/uploads/2024/06/singingStaffPhoto.png" 
            alt="OGP Staff" 
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;