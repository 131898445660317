import React, { useState, useEffect } from 'react';
import SeasonYear from '../utils/seasonYear';
import { LvDvConversion } from '../utils/lvDvConversion';
import { formatLevel } from '../utils/formatLevel';
import removeHiddenLevels from '../utils/hiddenLevel';

interface TeamStandingFilterProps {
  brand: number;
  selectedYear: string;
  onYearChange: (year: string) => void;
  onLvChange: (levelOfPlay: string) => void;
  onDvChange: (groupDivision: string) => void;
  
  selectedLevel: string;
  selectedDivision: string;
}

const TeamStandingFilter: React.FC<TeamStandingFilterProps> = ({
  brand,
  selectedYear,
  onYearChange,
  selectedLevel,
  onLvChange,
  selectedDivision,
  onDvChange
}) => {
  const [levels, setLevels] = useState<string[]>([]);
  const [divisions, setDivisions] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchLevelsAndDivisions = async () => {
      try {
        const levelDivisions = await LvDvConversion(brand);
        const uniqueLevels = Array.from(new Set(levelDivisions.map(ld => ld.level)));
        const uniqueDivisions = Array.from(new Set(levelDivisions.map(ld => ld.division)));

        setLevels(removeHiddenLevels(uniqueLevels));
        setDivisions(uniqueDivisions);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLevelsAndDivisions();
  }, [brand]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Ensure this is called
    onYearChange(selectedYear); 
    onLvChange(selectedLevel); 
    onDvChange(selectedDivision);
  };

  return (
    <div className="flex justify-center items-center py-6 pl-1.25">
      <div className="w-full max-w-6xl">
        <section className="flex flex-wrap mb-6 text-black">
          <form id="ts-form" className="grid grid-cols-1 md:grid-cols-4 gap-4" onSubmit={handleSubmit}>
            <div className="w-full">
              <SeasonYear selectedYear={selectedYear} onYearChange={onYearChange} />
            </div>

            <div className="w-full">
              <select
                name="lv_of_play"
                id="lv_of_play"
                className="w-full p-3 rounded-lg border border-gray-300 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={selectedLevel}
                onChange={(e) => onLvChange(e.target.value)} // Update parent state
              >
                <option value="All">All Levels of Play</option>
                {levels.length > 0 ? (
                  levels.map(level => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  ))
                ) : (
                  <option disabled>{loading ? 'Loading Levels...' : 'No Levels Available'}</option>
                )}
              </select>
            </div>

            <div className="w-full">
              <select
                name="group_lv"
                id="group_lv"
                className="w-full p-3 rounded-lg border border-gray-300 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={selectedDivision}
                onChange={(e) => onDvChange(e.target.value)} // Update parent state
              >
                <option value="All">All Divisions</option>
                {divisions.length > 0 ? (
                  divisions.map(division => (
                    <option key={division} value={division}>
                      {formatLevel(division)}
                    </option>
                  ))
                ) : (
                  <option disabled>{loading ? 'Loading Divisions...' : 'No Divisions Available'}</option>
                )}
              </select>
            </div>

            {/*<div className="w-full flex justify-center">
              <input
                type="submit"
                id="cts_submit_btn"
                value="Filter Options"
                className="w-full md:w-auto bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg cursor-pointer transition-all duration-300"
              />
            </div>*/}
          </form>
        </section>
      </div>
    </div>
  );
};


export default TeamStandingFilter;
