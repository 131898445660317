import gql from 'graphql-tag';

export const EVENT_DATA_MUTATION = gql`
  mutation EventSearch($condition: String!) {
    eventSearch(condition: $condition) {
      id
      name
      short_name
      dates
      logo_img
      link
    }
  }
`;