import React from 'react';

const PageLoadDesign: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="relative w-24 h-24 bg-orange-600 rounded-full animate-spin-slow">
        {/* Outer shadow to give depth */}
        <div className="absolute inset-0 shadow-inner shadow-lg rounded-full"></div>

        {/* Horizontal line */}
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="w-20 h-2 bg-black rounded-full"></div>
        </div>

        {/* Vertical line */}
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="h-20 w-2 bg-black rounded-full"></div>
        </div>

        {/* Diagonal lines */}
        <div
          className="absolute inset-0 flex justify-center items-center"
          style={{ transform: 'rotate(45deg)' }}
        >
          <div className="w-20 h-2 bg-black rounded-full"></div>
        </div>
        <div
          className="absolute inset-0 flex justify-center items-center"
          style={{ transform: 'rotate(-45deg)' }}
        >
          <div className="w-20 h-2 bg-black rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default PageLoadDesign;
