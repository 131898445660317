import { TEAM_STANDING_MUTATION } from '../queries/teamStandingQuery';
import client from '../../apolloClient';

interface RequestDataProps {
  brand: number;
  year: string;
  levelOfPlay: string;
  getDivision: string;
}

export const fetchTeamStandings = async ({ brand, year, levelOfPlay, getDivision }: RequestDataProps) => {
  const previousYear = Number(year) - 1;
  const select_year = `${previousYear}-09-01 AND ${year}-08-31`;
  const level_of_play = levelOfPlay || 'All';
  const division = getDivision || 'All';
  const win_loss_percent_cutoff = 0.5;
  const show_girls = false;
  const max_results_per_division = 10;

  try {
    const { data } = await client.mutate({
      mutation: TEAM_STANDING_MUTATION,
      variables: {
        brand,
        select_year,
        level_of_play,
        division,
        win_loss_percent_cutoff,
        show_girls,
        max_results_per_division,
      },
    });

    if (data && data.publicTeamStanding) {
      return data.publicTeamStanding;
    } else {
      throw new Error('No team standings found');
    }
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};
