import React, { useState } from 'react';
import TournamentManagerModal from '../components/TournamentManagerModal';
import SppEvents from '../logic/endpoints/sppEvents';
import LiveSearch from '../utils/searchBox';

interface Event {
  id: number;
  name: string;
  short_name: string;
  dates: string;
  logo_img: string;
  link: string;
}

const TournamentManager: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const requestDataProps = { requestDataType: '' };
  function isEventSearch(props: any): props is { props: { children: Event[] } } {
    return props && props.props && Array.isArray(props.props.children);
  }
  const eventSearchData = SppEvents(requestDataProps);
  const requestEventProps = isEventSearch(eventSearchData)
    ? eventSearchData.props.children
    : [];
  
  return (
    <div className="ml-5 mr-5">
      <div className="flex justify-center items-center">
        <h1>Tournament Manager</h1>
      </div>
      <div className="flex justify-center items-center">
        <LiveSearch searchData={requestEventProps} getPlaceholder='Event name' pageType='tournament-manager' />
      </div>
      <div>
        <div className="flex justify-center items-center">
          <h1 className="text-2xl">Event Name</h1>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="flex md:flex-col w-3/6 items-start justify-center">
            <p className="md:w-3/6">Paid Players: 573/1538 (37%)</p>
            <p className="md:w-3/6">Total number of Teams: 159</p>
          </div>
          <div className="flex w-full flex-col pt-2 md:flex-row md:w-3/6 md:items-center md:justify-end">
            <button className="mb-2 ml-4 text-black bg-customSilver shadow-inset shadow-outset py-2 px-4 rounded-lg">Pull Exposure Data</button>
            <button className="ml-4 text-black bg-customSilver shadow-inset shadow-outset py-2 px-4 rounded-lg">Download first import for Eposure</button>
          </div>
        </div>
        <div className="overflow-x-auto">
          {isModalOpen && <TournamentManagerModal closeModal={closeModal} />}
          <table className="w-full">
            <thead>
              <tr>
                <th>Action</th>
                <th>Division</th>
                <th>Club Team</th>
                <th>Level</th>
                <th>Roster ID</th>
                <th>Players</th>
                <th>% Paid</th>
                <th>% Verified</th>
                <th>% Claimed</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-customRed">
                  <button className="button bg-customBlack shadow-inset shadow-outset py-1 px-2 rounded-lg mr-4">Edit</button>
                  <button className="button bg-customBlack shadow-inset shadow-outset py-1 px-2 rounded-lg">Delete</button>
                </td>
                <td className="bg-customRed">15U</td>
                <td className="bg-customRed">AZ Gremlins (Arizona Gremlins) 15U 3SGB</td>
                <td className="bg-customRed">Gold</td>
                <td className="bg-customRed">75777</td>
                <td className="bg-customRed">
                  <button className="button bg-customBlack shadow-inset shadow-outset py-1 px-2 rounded-lg" onClick={openModal}>Toggle Players</button>
                </td>
                <td className="bg-customRed">1/9 (11%)</td>
                <td className="bg-customRed">9/9 (100%)</td>
                <td className="bg-customRed">9/9 (100%)</td>
              </tr>
              <tr>
                <td>
                  <button className="button bg-customBlack shadow-inset shadow-outset py-1 px-2 rounded-lg mr-4">Edit</button>
                  <button className="button bg-customBlack shadow-inset shadow-outset py-1 px-2 rounded-lg">Delete</button>
                </td>
                <td>15U</td>
                <td>AZ Gremlins (Arizona Gremlins) 15U 3SGB</td>
                <td>Gold</td>
                <td>75777</td>
                <td>
                  <button className="button bg-customBlack shadow-inset shadow-outset py-1 px-2 rounded-lg">Toggle Players</button>
                </td>
                <td>1/9 (11%)</td>
                <td>9/9 (100%)</td>
                <td>9/9 (100%)</td>
              </tr>
              <tr>
                <td>
                  <button className="button bg-customBlack shadow-inset shadow-outset py-1 px-2 rounded-lg mr-4">Edit</button>
                  <button className="button bg-customBlack shadow-inset shadow-outset py-1 px-2 rounded-lg">Delete</button>
                </td>
                <td>15U</td>
                <td>AZ Gremlins (Arizona Gremlins) 15U 3SGB</td>
                <td>Gold</td>
                <td>75777</td>
                <td>
                  <button className="button bg-customBlack shadow-inset shadow-outset py-1 px-2 rounded-lg">Toggle Players</button>
                </td>
                <td>1/9 (11%)</td>
                <td>9/9 (100%)</td>
                <td>9/9 (100%)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TournamentManager;