import { TS_GAME_RESULT_MUTATION } from '../queries/tsGameResultQuery';
import client from '../../apolloClient';

interface RequestDataProps {
  year: string;
  brand: number;
  teamOrg: number;
  teamId: number;
}

export const fetchGameResults = async ({ year, brand, teamOrg, teamId }: RequestDataProps) => {
  const previousYear = Number(year) - 1;
  const select_year = `${previousYear}-09-01 AND ${year}-08-31`;

  try {
    const { data } = await client.mutate({
      mutation: TS_GAME_RESULT_MUTATION,
      variables: { brand, select_year, teamOrg, teamId },
    });

    if (data && data.publicTeamStandingGameResult) {
      return data.publicTeamStandingGameResult;
    } else {
      throw new Error('No team game results found');
    }
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};