import { useMutation } from '@apollo/client';
import { LEVEL_DIVISION_MUTATION } from '../logic/queries/levelDivisionQuery';
import client from '../apolloClient';

interface LevelDivision {
  lv_dv: {
    [key: string]: {
      [key: string]: string;
    } | string;
  };
  __typename: string;
}

// Convert the level division data into the desired format
const convertLevelDivision = (data: LevelDivision[]): { division: number; level: string }[] => {
  const result: { division: number; level: string }[] = [];
  const uniqueResults = new Set<string>();

  data.forEach(item => {
    const divisions = item.lv_dv;
    Object.entries(divisions).forEach(([divisionKey, levels]) => {
      if (typeof levels === 'object') {
        Object.keys(levels).forEach(level => {
          const division = Number(divisionKey);
          const identifier = `${division}-${level}`;
          if (!uniqueResults.has(identifier)) {
            uniqueResults.add(identifier);
            result.push({ division, level });
          }
        });
      }
    });
  });

  return result;
};

// Function to fetch and convert level divisions
export const LvDvConversion = async (brand: number): Promise<{ division: number; level: string }[]> => {
  try {
    const { data } = await client.mutate({
      mutation: LEVEL_DIVISION_MUTATION,
      variables: { brand },
    });
    if (data && data.publicTSLevelDivision) {
      return convertLevelDivision(data.publicTSLevelDivision);
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching level divisions:", error);
    return [];
  }
};
