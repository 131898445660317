import gql from 'graphql-tag';

export const CALENDAR_DATA_MUTATION = gql`
  mutation eventCalendar($condition: String!) {
    eventCalendar(condition: $condition) {
      id
      name
      short_name
      dates
      logo_img
      link
      locations
    }
  }
`;