import { BOX_SCORE_MUTATION } from '../queries/boxScoreQuery';
import client from '../../apolloClient';

interface RequestDataProps {
  year: string;
  teamId: number;
  gameId: number;
}

export const fetchBoxScores = async ({ year, teamId, gameId }: RequestDataProps) => {
  const previousYear = Number(year) - 1;
  const selectYear = `${previousYear}-09-01 AND ${year}-08-31`;
  const maxResultsPerDivision = 20;

  try {
    const { data } = await client.mutate({
      mutation: BOX_SCORE_MUTATION,
      variables: { selectYear, teamId, gameId, maxResultsPerDivision },
    });

    if (data && data.publicBoxScore) {
      return data.publicBoxScore;
    } else {
      throw new Error('No team boxscore found');
    }
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};