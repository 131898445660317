import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { fetchTeamStandings } from '../logic/endpoints/teamStandings';
import { customVariables } from '../utils/customVariables';
import TsGameResults from '../components/tsGameResults';
import TeamStandingFilter from '../components/teamStandingFilter';
import { seasonYear } from '../utils/formatDate';
import { getDivisions } from '../utils/divisionFormat';

interface RequestDataProps {
  requestDataType: string;
}

interface TeamStandings {
  org_id: number;
  team_id: number;
  level_of_play: string;
  division: number;
  full_team_name: string;
  org_logo: string;
  total_wins: number;
  total_losses: number;
  win_percentage: number;
  ppg: number;
  opp_ppg: number;
}

interface TeamStandingProps {
  brand: number;
  year: string;
  levelOfPlay: string;
  getDivision: string;
}

interface BoxScoreProps {
  year: string;
  getTeamId: number;
  getGameId: number;
}

const TeamStanding: React.FC<RequestDataProps> = ({ requestDataType }) => {
  const { brandId } = useParams<{ brandId: string }>();
  const [gameResultsTeam, setGameResultsTeam] = useState<TeamStandings | null>(null);
  const [boxScoreTeam, setBoxScoreTeam] = useState<TeamStandings | null>(null);
  const [selectedYear, setSelectedYear] = useState<string>(seasonYear);
  const [selectedLv, setSelectedLv] = useState<string>('All');
  const [selectedDv, setSelectedDv] = useState<string>('All');
  const [teamStandingResult, setTeamStandingResult] = useState<TeamStandings[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isBoxScoreLoading, setIsBoxScoreLoading] = useState<boolean>(true);
  const [boxScoreResult, setBoxScoreResult] = useState<any[] | null>(null);
  
  const [selectedLevel, setSelectedLevel] = useState<string>('All');
  const [selectedDivision, setSelectedDivision] = useState<string>('All');
  
  const defaultBrand = '3191'; // G365

  // Fetch the team standings when the dependencies change
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const teamStandingData: TeamStandingProps = {
          brand: parseInt(brandId || defaultBrand, 10),
          year: selectedYear,
          levelOfPlay: selectedLv,
          getDivision: selectedDv,
        };

        const result = await fetchTeamStandings(teamStandingData);
        setTeamStandingResult(result);
      } catch (error) {
        setErrorMessage("Error fetching team standings.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [brandId, selectedYear, selectedLv, selectedDv]);

  // `useCallback` must be outside of conditional statements
  const viewResult = useCallback(
    _.debounce((team: TeamStandings) => {
      if (gameResultsTeam?.team_id !== team.team_id) {
        setGameResultsTeam(team);
        setBoxScoreTeam(team);
      } else {
        setGameResultsTeam(null);
        setBoxScoreTeam(null);
      }
    }, 300),
    [gameResultsTeam]
  );

  // Handle early return inside the render function
  if (errorMessage) return <div>Error: {errorMessage}</div>;

  const groupedByDivision = teamStandingResult?.reduce((newDivision: Record<number, TeamStandings[]>, team: TeamStandings) => {
    if (!newDivision[team.division]) {
      newDivision[team.division] = [];
    }
    newDivision[team.division].push(team);
    return newDivision;
  }, {}) || {};

  const sortedDivisions = Object.keys(groupedByDivision).map(Number).sort((a, b) => b - a);

  const handleLevelChange = (level: string) => {
    setSelectedLevel(level);
    setSelectedLv(level);
  };
  const handleDivisionChange = (division: string) => {
    setSelectedDivision(division);
    setSelectedDv(division);
  };

  const removeDot = (input: string): string => {
    return input.replace(/\./g, '');
  };
      
  return (
    <>
      <TeamStandingFilter
        brand={parseInt(brandId || defaultBrand, 10)}
        selectedYear={selectedYear}
        onYearChange={setSelectedYear}
        selectedLevel={selectedLevel}
        onLvChange={handleLevelChange}
        selectedDivision={selectedDivision}
        onDvChange={handleDivisionChange}
      />
      <div className="flex justify-center min-h-screen">
        <div className="w-full max-w-6xl p-5">
          { sortedDivisions.length ? (
            sortedDivisions.map((division) => (
              <div key={division} className="mb-8">
                <h2 className="text-lg font-bold mb-4 text-left">{getDivisions(division) + ' ' + (selectedLv === 'All' ? 'All Levels' : selectedLv)}</h2>

                <div className="overflow-x-auto">
                  <table className="cell cts_tb small-padding-bottom w-full">
                    <thead>
                      <tr>
                        <th className="text-left"><span>TEAM NAME</span></th>
                        <th className="text-left"><span>W</span></th>
                        <th className="text-left"><span>L</span></th>
                        <th className="text-left"><span>PCT</span></th>
                        <th className="text-left"><span>PPG</span></th>
                        <th className="text-left"><span>OPP PPG</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedByDivision[division].map((team: TeamStandings) => (
                        <React.Fragment key={team.team_id}>
                          <tr>
                            <td>
                              <div className="flex items-center">
                                <span
                                  className="vr_btn mr-5 bg-white text-black px-3 py-1 rounded cursor-pointer flex items-center"
                                  id={team.team_id.toString()}
                                  onClick={() => viewResult(team)}
                                >
                                  Box Scores
                                  <svg
                                    className={`w-4 h-4 ml-2 transition-transform ${
                                      gameResultsTeam?.team_id === team.team_id ? 'rotate-180' : 'rotate-0'
                                    }`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M19 9l-7 7-7-7"
                                    />
                                  </svg>
                                </span>
                                <span className="small-margin-right">
                                  <img
                                    style={{ height: '25px', width: '35px' }}
                                    alt={team.full_team_name}
                                    title={team.full_team_name}
                                    src={
                                      team.org_logo
                                        ? `${customVariables('spp-club-logo', [])}${team.org_logo}`
                                        : customVariables('spp-club-logo-placeholder', [])
                                    }
                                  />
                                </span>
                                <span>{removeDot(team.full_team_name)}</span>
                              </div>
                            </td>
                            <td>{team.total_wins}</td>
                            <td>{team.total_losses}</td>
                            <td>{(team.win_percentage * 100).toFixed(1)}%</td>
                            <td>{team.ppg.toFixed(1)}</td>
                            <td>{team.opp_ppg.toFixed(1)}</td>
                          </tr>
                          {gameResultsTeam?.team_id === team.team_id && (
                            <tr>
                              <td colSpan={6}>
                                <TsGameResults
                                  year={selectedYear}
                                  getBrand={parseInt(brandId || defaultBrand, 10)}
                                  getTeamOrg={team.org_id}
                                  getTeamId={team.team_id}
                                />
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))
            ) : (
            <div className="text-center text-lg">No Team Standings available for the selected options.</div>
          )}
        </div>
      </div>
    </>
  );
};

export default TeamStanding;