export const getDivisions = (division: number): string | undefined => {
  const divisionMap: { [key: number]: string } = {
    8: "8U",
    9: "9U",
    10: "10U",
    11: "11U",
    12: "12U",
    13: "13U",
    14: "14U",
    15: "15U",
    16: "16U",
    17: "17U",
    18: "18U",
    40: "Girls 4th",
    41: "Girls 5th",
    42: "Girls 6th",
    43: "Girls 7th",
    44: "Girls 8th",
    45: "Girls 9th",
    46: "Girls 10th",
    47: "Girls 11th",
    74: "Frosh/Soph",
    75: "JV",
    76: "Varsity",
  };

  return divisionMap[division];
};