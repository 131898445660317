import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

const SignOut: React.FC = () => {
  const navigate = useNavigate();
  const client = useApolloClient();

  const handleSignOut = async () => {
    try {
      // Sign out from Firebase
      await signOut(auth);

      // Clear Apollo Client's cache
      await client.clearStore();

      // Optionally, remove the token from localStorage
      localStorage.removeItem('token');

      // Navigate to the sign-in page
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <button
      onClick={handleSignOut}
      className="py-3 px-6 bg-red-600 text-white font-semibold rounded-md shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition ease-in-out duration-200"
    >
      Sign Out
    </button>
  );
};

export default SignOut;