import React from 'react';

interface SeasonYearProps {
  selectedYear: string;
  onYearChange: (year: string) => void;
}

const SeasonYear: React.FC<SeasonYearProps> = ({ selectedYear, onYearChange }) => {
  // Get current year and month
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // getMonth() is 0-based, so +1

  // Determine if a new season (current year + 1) needs to be added
  const seasons = [];
  const firstYear = 2022; // Starting from 2022

  // Add the current year and next year if current month > August (8)
  for (let year = firstYear; year <= currentYear; year++) {
    seasons.push({
      value: year.toString(),
      label: `${year - 1}-${year.toString()} Season`
    });
  }

  if (currentMonth > 8) {
    seasons.push({
      value: (currentYear + 1).toString(),
      label: `${currentYear}-${(currentYear + 1).toString()} Season`
    });
  }

  // Reverse the seasons array to show the newest season first
  const sortedSeasons = seasons.reverse();

  return (
    <div className="w-full">
      <select
        name="g365_year"
        id="year"
        className="w-full p-3 rounded-lg border border-gray-300 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={selectedYear}
        onChange={(e) => onYearChange(e.target.value)}
      >
        {sortedSeasons.map(season => (
          <option key={season.value} value={season.value}>
            {season.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SeasonYear;