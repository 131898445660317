import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SignOut from '../pages/signOut';

const DashboardNav: React.FC = () => {
  const [isFeaturesOpen, setIsFeaturesOpen] = useState(false);
  const [isSPPToolsOpen, setIsSPPToolsOpen] = useState(false);
  const [isTournamentsOpen, setIsTournamentsOpen] = useState(false);

  const toggleFeatures = () => {
    setIsFeaturesOpen(!isFeaturesOpen);
  };
  
  const toggleSPPTools = () => {
    setIsSPPToolsOpen(!isSPPToolsOpen);
  };
  
  const toggleTournaments = () => {
    setIsTournamentsOpen(!isTournamentsOpen);
  };

  return (
    <div className="flex h-screen bg-gray-800 text-white">
      <aside className="w-64 bg-gray-900 p-6">
        <h2 className="text-2xl font-semibold mb-8">Dashboard</h2>
        <nav>
          <ul className="space-y-4">
            <li>
              <button
                onClick={toggleTournaments}
                className="w-full text-left flex items-center justify-between p-2 rounded hover:bg-gray-700 transition"
              >
                Tournaments
                <svg
                  className={`w-4 h-4 transition-transform ${isTournamentsOpen ? 'rotate-180' : 'rotate-0'}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {isTournamentsOpen && (
                <ul className="mt-2 space-y-2 bg-[#33333A] rounded">
                  <li>
                    <Link
                      to="/tournament-manager"
                      className="block w-full p-2 rounded hover:bg-gray-600 transition pl-4"
                    >
                      Tournament Manager
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/player"
                      className="block w-full p-2 rounded hover:bg-gray-600 transition pl-4"
                    >
                      Player
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <button
                onClick={toggleFeatures}
                className="w-full text-left flex items-center justify-between p-2 rounded hover:bg-gray-700 transition"
              >
                Features
                <svg
                  className={`w-4 h-4 transition-transform ${isFeaturesOpen ? 'rotate-180' : 'rotate-0'}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {isFeaturesOpen && (
                <ul className="mt-2 space-y-2 bg-[#33333A] rounded">
                  <li>
                    <Link
                      to="/upcoming-event/7164"
                      className="block w-full p-2 rounded hover:bg-gray-600 transition pl-4"
                    >
                      Upcoming Events
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/event-calendar/2_7164"
                      className="block w-full p-2 rounded hover:bg-gray-600 transition pl-4"
                    >
                      Event Calendar
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/team-standings/3191"
                      className="block w-full p-2 rounded hover:bg-gray-600 transition pl-4"
                    >
                      Team Standings
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <button
                onClick={toggleSPPTools}
                className="w-full text-left flex items-center justify-between p-2 rounded hover:bg-gray-700 transition"
              >
                The Passport Tools
                <svg
                  className={`w-4 h-4 transition-transform ${isSPPToolsOpen ? 'rotate-180' : 'rotate-0'}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {isSPPToolsOpen && (
                <ul className="mt-2 space-y-2 bg-[#33333A] rounded">
                  <li>
                    <Link
                      to="/player-program-merge"
                      className="block w-full p-2 rounded hover:bg-gray-600 transition pl-4"
                    >
                     Merge Players and Programs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/passport-report"
                      className="block w-full p-2 rounded hover:bg-gray-600 transition pl-4"
                    >
                      The Passport Report
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <SignOut />
            </li>
          </ul>
        </nav>
      </aside>
    </div>
  );
};

export default DashboardNav;