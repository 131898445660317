export const siteUrl = (fullUrl: string, type: string): string => {
  switch (type) {
    case 'endpoints':
      if (fullUrl.includes('admin.') || fullUrl.includes('master.')) {
        return 'https://api.sportspassports.com';
      } else {
        return 'http://dev.statplatform.sportspassports.com:3001';
      }

    case 'getSiteUrl':
      if (fullUrl.includes('engineerings.sportspassports.com')) {
        return 'https://dev.sportspassports.com';
      } else {
        return 'https://sportspassports.com';
      }

    default:
      // Throw an error if no matching case is found
      throw new Error(`No matching case for type: ${type}`);
  }
};