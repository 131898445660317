import React from 'react';
import { useParams } from 'react-router-dom';
import UpcomingEvents from '../logic/endpoints/upcomingEvents';
import { formatDate } from '../utils/formatDate';
import OrgList from '../components/orgList';
import { useAuth } from '../logic/authContext'; 

interface RequestDataProps {
  requestDataType: number;
}

interface Event {
  id: number;
  name: string;
  short_name: string;
  dates: string;
  logo_img: string;
  link: string;
  locations: string;
}

const UpcomingEvent: React.FC<RequestDataProps> = ({ requestDataType }) => {
  const { user } = useAuth();
  let renderOrg: JSX.Element | null = null;
  if(user){ renderOrg = <OrgList />; }
  const { orgId } = useParams<{ orgId: string }>();
  const requestDataProps: RequestDataProps = { requestDataType: parseInt(orgId ?? '0') };

  function isUpcomingEvents(props: any): props is { props: { children: Event[] } } {
    return props && props.props && Array.isArray(props.props.children);
  }
  
  const upcomingEventsResult = UpcomingEvents(requestDataProps);
  const requestEventProps = isUpcomingEvents(upcomingEventsResult)
    ? upcomingEventsResult.props.children
    : [];
  
  // Determine if the background should be #0F0F0F and text color #FF0
  const isOrgId2 = orgId === '2';

  return (
    <div
      className={`min-h-screen p-4 ${isOrgId2 ? 'bg-[#0F0F0F] text-[#FF0]' : 'bg-white text-black'}`}
    >
      { renderOrg }
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {requestEventProps.map((event: Event) => (
          <a
            key={event.id}
            href={event.link}
            className="flex flex-col items-center p-2"
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer"
          >
            <img
              src={event.logo_img}
              alt={event.name}
              className="w-full h-auto mb-2"
            />
            <h2 className="text-sm font-semibold text-center">
              {event.name}
            </h2>
            <h2 className="text-sm font-semibold text-center">
              {formatDate(event.dates)}
            </h2>
          </a>
        ))}
      </div>
    </div>
  );
};

export default UpcomingEvent;