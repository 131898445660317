import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './authContext';
import { onAuthStateChanged, auth } from '../firebase';
import PageLoadDesign from '../utils/pageLoadDesign';

const ProtectedRoute: React.FC = () => {
  const { user } = useAuth();
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthChecked(true);
    });
    return unsubscribe;
  }, []);

  // If authentication status hasn't been checked yet, show a centered loading design
  if (!isAuthChecked) {
    return <PageLoadDesign />;
  }

  // If the user is authenticated, render the protected content
  if (user) {
    return <Outlet />;
  }

  // If the user is not authenticated, redirect to the sign-in page
  return <Navigate to="/signin" />;
};

export default ProtectedRoute;