import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { siteUrl } from './utils/siteUrl';

const client = new ApolloClient({
  link: new HttpLink({
    uri: `${siteUrl(window.location.href, 'endpoints')}/graphql`,
  }),
  cache: new InMemoryCache(),
});

export default client;
