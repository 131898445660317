import { initializeApp } from 'firebase/app';
import { getAuth, Auth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';
import firebaseConfig from './firebaseConfig';

import { setPersistence, browserLocalPersistence } from 'firebase/auth';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth: Auth = getAuth(app);

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Existing and future Auth states are now persisted across page reloads.
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });


// Initialize Firebase services
export const firestore: Firestore = getFirestore(app);
export { onAuthStateChanged };