import React, { useState, useEffect } from 'react';
import { fetchGameResults } from '../logic/endpoints/tsGameResultFn';
import BoxScores from './boxScores';
import { siteUrl } from '../utils/siteUrl';

interface RequestDataProps {
  year: string;
  getBrand: number;
  getTeamOrg: number;
  getTeamId: number;
}

interface GameResult {
  team_id: number;
  event_id: number;
  game_id: number;
  event_name: string;
  org: number;
  roster_id: number;
  score: number;
  opp_org_id: number;
  opp_roster_id: number;
  opp_score: number;
  outcome: string;
  level: number;
  full_team_name: string;
  org_logo: string;
  opp_full_team_name: string;
  opp_org_logo: string;
}

const GameResults: React.FC<RequestDataProps> = ({ year, getBrand, getTeamOrg, getTeamId }) => {
  const [gameResult, setGameResult] = useState<GameResult[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedGameId, setSelectedGameId] = useState<number | null>(null);
  const [isBoxScoresOpen, setIsBoxScoresOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchGameResults({
          brand: getBrand || 3191,
          year: year || '2025',
          teamOrg: getTeamOrg,
          teamId: getTeamId,
        });
        setGameResult(result);
      } catch (error) {
        setErrorMessage('Error fetching team standings.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [getBrand, year, getTeamOrg, getTeamId]);

  const handleOpenBoxScores = (gameId: number) => {
    setSelectedGameId(gameId);
    setIsBoxScoresOpen(true);
  };

  const handleCloseBoxScores = () => {
    setIsBoxScoresOpen(false);
  };

  // Group the game results by event_name
  const groupByEventName = (gameResults: GameResult[]) => {
    return gameResults.reduce((groups, result) => {
      if (!groups[result.event_name]) {
        groups[result.event_name] = [];
      }
      groups[result.event_name].push(result);
      return groups;
    }, {} as { [key: string]: GameResult[] });
  };

  if (errorMessage) return <div>{errorMessage}</div>;

  const groupedResults = groupByEventName(gameResult);

  return (
    <div>
      {Object.keys(groupedResults).map((eventName) => (
        <div key={eventName}>
          <h3 className="text-center" style={{ textDecoration: 'underline' }}>{eventName}</h3>
          <table className="w-full text-center border-collapse">
            <tbody>
              {groupedResults[eventName].map((game) => (
                <tr key={game.game_id} className="result_box border-b">
                  <td className="p-4">
                    <a
                      href={`${siteUrl(window.location.href, 'getSiteUrl')}/club/${game.org}/teams`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ height: '50px', width: '75px' }}
                        alt={game.full_team_name}
                        title={game.full_team_name}
                        src={
                          game.org_logo
                            ? `${siteUrl(window.location.href, 'getSiteUrl')}/wp-content/uploads/org-logos/${game.org_logo}`
                            : `${siteUrl(window.location.href, 'getSiteUrl')}/wp-content/uploads/org-logos/g365_blank-placeholder_400x300.png`
                        }
                      />
                    </a>
                  </td>
                  {/* Column 2: Team Name with fixed width */}
                  <td className="p-4" style={{ width: '200px' }}>{game.full_team_name}</td>
                  {/* Column 3: Game Result + Box Score Button */}
                  <td className="p-4">
                    <div className="flex flex-col items-center">
                      <span className="font-bold">{`${game.outcome} ${game.score} - ${game.opp_score}`}</span>
                      <button
                        className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={() => handleOpenBoxScores(game.game_id)}
                      >
                        Box Score
                      </button>
                    </div>
                  </td>
                  {/* Column 4: Opponent Name with fixed width */}
                  <td className="p-4" style={{ width: '200px' }}>{game.opp_full_team_name}</td>
                  <td className="p-4">
                    <div className="flex justify-end">
                      <a
                        href={`${siteUrl(window.location.href, 'getSiteUrl')}/club/${game.opp_org_id}/teams`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          style={{ height: '50px', width: '75px' }}
                          alt={game.opp_full_team_name}
                          title={game.opp_full_team_name}
                          src={
                            game.opp_org_logo
                              ? `${siteUrl(window.location.href, 'getSiteUrl')}/wp-content/uploads/org-logos/${game.opp_org_logo}`
                              : `${siteUrl(window.location.href, 'getSiteUrl')}/wp-content/uploads/org-logos/g365_blank-placeholder_400x300.png`
                          }
                        />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}

      {/* Conditionally render BoxScores if a game is selected and open */}
      {selectedGameId && (
        <BoxScores
          year={year}
          getGameId={selectedGameId}
          getTeamId={getTeamId}
          open={isBoxScoresOpen}
          onClose={handleCloseBoxScores}
        />
      )}
    </div>
  );
};

export default GameResults;

