import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import client from '../../apolloClient';
import { EVENT_DATA_MUTATION } from '../queries/eventSearchQuery';

interface RequestDataProps {
  requestDataType: string;
}

const SppEvents: React.FC<RequestDataProps> = ({ requestDataType }) => {
  const [eventData, setEventData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const token = localStorage.getItem('token');
  const condition = "WHERE enabled = 1";

  // Apollo mutation hook
  const [eventSearch, { error }] = useMutation(EVENT_DATA_MUTATION, {
    variables: { condition },
    client,
    context: {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await eventSearch();
        if (data && data.eventSearch) {
          setEventData(data.eventSearch);
        }
      } catch (err) {
        setErrorMessage("Error fetching events.");
        console.error('Fetch error:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [eventSearch, condition]);

  if (isLoading) return <div>Loading...</div>;
  if (error || errorMessage) return <div>Error: {errorMessage || error?.message}</div>;

  return <div>{eventData}</div>;
};

export default SppEvents;