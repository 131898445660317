import gql from 'graphql-tag';

export const TS_GAME_RESULT_MUTATION = gql`
  mutation PublicTeamStandingGameResult(
    $brand: Int!,
    $select_year: String!, 
    $teamOrg: Int!, 
    $teamId: Int!, 
  ) {
    publicTeamStandingGameResult(
      brand: $brand, 
      select_year: $select_year, 
      teamOrg: $teamOrg, 
      teamId: $teamId
    ) {
      event_id
      game_id
      event_name
      org
      roster_id
      score
      opp_org_id
      opp_roster_id
      opp_score
      outcome
      level
      full_team_name
      org_logo
      opp_full_team_name
      opp_org_logo
    }
  }
`;