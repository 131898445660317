import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { EVENT_PUBLIC_MUTATION } from '../queries/upcomingEventQuery';
import client from '../../apolloClient';

interface RequestDataProps {
  requestDataType: number;
}

const UpcomingEvents: React.FC<RequestDataProps> = ({ requestDataType }) => {
  const [eventData, setEventData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const condition = `WHERE org = ${requestDataType} AND eventtime >= NOW() AND enabled = 1 ORDER BY eventtime ASC LIMIT 4`;

  // Apollo mutation hook
  const [eventPublic, { error }] = useMutation(EVENT_PUBLIC_MUTATION, {
    variables: { condition },
    client,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await eventPublic();
        if (data && data.eventPublic) {
          setEventData(data.eventPublic);
        }
      } catch (err) {
        setErrorMessage("Error fetching events.");
        console.error('Fetch error:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [eventPublic, condition]);

  if (isLoading) return <div>Loading...</div>;
  if (error || errorMessage) return <div>Error: {errorMessage || error?.message}</div>;

  return <div>{eventData}</div>;
};

export default UpcomingEvents;