import LiveSearch from '../utils/searchBox';
import PlayerDirectory from '../logic/endpoints/playerDirectory';

const Player: React.FC = () => {
  const requestDataProps = { requestDataType: '' };
  function isPlayerSearch(props: any): props is { props: { children: Event[] } } {
    return props && props.props && Array.isArray(props.props.children);
  }
  const playerSearchData = PlayerDirectory(requestDataProps);
  const requestPlayerProps = isPlayerSearch(playerSearchData)
    ? playerSearchData.props.children
    : [];
  
  return (
    <div>
      <LiveSearch searchData={requestPlayerProps} getPlaceholder='Player name' pageType='player' />
    </div>
  );
};

export default Player;