const levelMap: { [key: number]: string } = {
  8: "8U/2nd Grade",
  9: "9U/3rd Grade",
  10: "10U/4th Grade",
  11: "11U/5th Grade",
  12: "12U/6th Grade",
  13: "13U/7th Grade",
  14: "14U/8th Grade",
  15: "15U/9th Grade",
  16: "16U/10th Grade",
  17: "17U/11th Grade",
  39: "Girls 3rd Grade",
  40: "Girls 4th Grade",
  41: "Girls 5th Grade",
  42: "Girls 6th Grade",
  43: "Girls 7th Grade",
  44: "Girls 8th Grade",
  45: "Frosh/Soph Girls",
  46: "JV Girls",
  47: "Varsity Girls",
  61: "12U / 6th Grade and 13U / 7th Grade",
  62: "9U / 3rd Grade and 10U / 4th Grade",
  63: "8U / 2nd Grade and 9U / 3rd Grade",
  64: "Girls 6th Grade and Girls 7th Grade",
  65: "Girls 5th Grade and Girls 6th Grade",
  66: "Girls 7th Grade and Girls 8th Grade",
  67: "JV West",
  68: "JV East",
  69: "HS Girls Gold",
  70: "14U Girls / 8th Grade",
  71: "13 Gold",
  72: "13 Silver/Bronze",
  73: "Girls 3rd Grade",
  74: "Frosh/Soph",
  75: "JV",
  76: "Varsity"
};

export const formatLevel = (level: number): string => {
  return levelMap[level] || `Unknown Level (${level})`;
};