export const formatDate = (dateRange: string): string => {
  const [startDate, endDate] = dateRange.split('|');

  const start = new Date(startDate.trim());
  
  if (!endDate) {
    // If there's no end date, return only the start date in the desired format
    const startDay = start.getDate();
    const startMonth = start.toLocaleString('default', { month: 'long' });
    return `${startMonth} ${startDay}, ${start.getFullYear()}`;
  }

  const end = new Date(endDate.trim());
  
  // Extract the day and month
  const startDay = start.getDate();
  const startMonth = start.toLocaleString('default', { month: 'long' });
  const endDay = end.getDate();

  // Construct the formatted date string
  return `${startMonth} ${startDay} - ${endDay}`;
};

export const seasonYear = (): string => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Month is 0-indexed, so add 1

  // If current month is greater than 8 (September or later), return current year + 1
  const seasonYear = currentMonth > 8 ? currentYear + 1 : currentYear;

  return seasonYear.toString();
};