import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { CALENDAR_DATA_MUTATION } from '../queries/eventCalendarQuery';
import client from '../../apolloClient';

interface RequestDataProps {
  requestDataType: string;
}

const EventCalendars: React.FC<RequestDataProps> = ({ requestDataType }) => {
  const [eventCalendarData, setEventCalendarData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const formattedRequestDataType = requestDataType.toString().replace(/_/g, ',');
  const condition = `WHERE org IN (${formattedRequestDataType}) AND eventtime >= NOW() AND enabled = 1 ORDER BY eventtime ASC`;

  // Apollo mutation hook
  const [eventCalendar, { error }] = useMutation(CALENDAR_DATA_MUTATION, {
    variables: { condition },
    client,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await eventCalendar();
        if (data && data.eventCalendar) {
          setEventCalendarData(data.eventCalendar);
        }
      } catch (err) {
        setErrorMessage("Error fetching events.");
        console.error('Fetch error:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [eventCalendar, condition]);

  if (isLoading) return <div>Loading...</div>;
  if (error || errorMessage) return <div>Error: {errorMessage || error?.message}</div>;

  return <div>{eventCalendarData}</div>;
};

export default EventCalendars;