import gql from 'graphql-tag';

export const LEVEL_DIVISION_MUTATION = gql`
  mutation PublicTSLevelDivision(
    $brand: Int!,
  ) {
    publicTSLevelDivision(
      brand: $brand, 
    ) {
      lv_dv
    }
  }
`;