import React from 'react'

interface ModalProps {
  closeModal: () => void;
}

const TournamentManagerModal: React.FC<ModalProps> = ({ closeModal }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="flex justify-center">
          <h2 className="text-center text-3xl">Team Name Here</h2>
          <button className="text-2xl modal-close text-red-600 relative" style={{right:"-39%"}} onClick={closeModal}>X</button>
        </div>
        <table className="w-full">
                     <thead>
                      <tr>
                        <th></th>
                        <th>#</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Attend</th>
                        <th>Paid</th>
                        <th>Grade</th>
                        <th>Birthday</th>
                        <th>Verification</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tournament-players verified-player">
                         <td className="bg-customRed"><img className="ml-4" style={{maxWidth: "4rem"}} loading="lazy" src="https://sportspassports.com/wp-content/uploads/player-profiles/cristian-jimenez_4952.jpg"/></td>
                         <td className="bg-customRed">3</td>
                         <td className="bg-customRed pl_name">Cristian Jimenez</td>
                         <td className="bg-customRed"></td>
                         <td className="bg-customRed">
                           <form className="flex flex-col items-start">
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Fri" checked={false}/> 
                              <p>Fri</p>
                             </div>
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Sat" checked={false}/>  
                              <p>Sat</p>
                             </div>
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Sun" checked={false}/>
                              <p>Sun</p>
                             </div>
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Mon"/>  
                              <p>Mon</p>
                             </div>
                           </form>
                         </td>
                         <td className="bg-customRed">
                             <div className="flex justify-center items-center">
                               <div className="flex flex-col justify-center items-center p-2 bg-customGray rounded-sm">
                                 <p className="">N/A</p>
                                 <p className="bg-customRed pl-1 pr-1 rounded-lg">No unlock</p>
                               </div>
                              </div>
                         </td>
                         <td className="bg-customRed">9</td>
                         <td className="bg-customRed">09/02/08</td>
                         <td className="bg-customRed">Verified</td>
                        </tr>
                        <tr className="tournament-players verified-player">
                         <td className="bg-customGreen"><img className="ml-4" style={{maxWidth: "4rem"}} loading="lazy" src="https://sportspassports.com/wp-content/uploads/player-profiles/cristian-jimenez_4952.jpg"/></td>
                         <td className="bg-customGreen">3</td>
                         <td className="bg-customGreen pl_name">Cristian Jimenez</td>
                         <td className="bg-customGreen"></td>
                         <td className="bg-customGreen">
                           <form className="flex flex-col items-start">
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Fri" checked={false}/> 
                              <p>Fri</p>
                             </div>
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Sat" checked={false}/>  
                              <p>Sat</p>
                             </div>
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Sun" checked={false}/>
                              <p>Sun</p>
                             </div>
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Mon"/>  
                              <p>Mon</p>
                             </div>
                           </form>
                         </td>
                         <td className="bg-customGreen">
                             <div className="flex justify-center items-center">
                               <div className="flex flex-col justify-center items-center p-2 bg-customGray rounded-sm">
                                 <p className="">N/A</p>
                                 <p className="bg-customGreen pl-1 pr-1 rounded-lg">No unlock</p>
                               </div>
                              </div>
                         </td>
                         <td className="bg-customGreen">9</td>
                         <td className="bg-customGreen">09/02/08</td>
                         <td className="bg-customGreen">Verified</td>
                        </tr>
                        <tr className="tournament-players verified-player">
                         <td><img className="ml-4" style={{maxWidth: "4rem"}} loading="lazy" src="https://sportspassports.com/wp-content/uploads/player-profiles/cristian-jimenez_4952.jpg"/></td>
                         <td className="">3</td><td className="pl_name">Cristian Jimenez</td>
                         <td className=""></td>
                         <td className="">
                           <form className="flex flex-col items-start">
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Fri" checked={false}/> 
                              <p>Fri</p>
                             </div>
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Sat" checked={false}/>  
                              <p>Sat</p>
                             </div>
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Sun" checked={false}/>
                              <p>Sun</p>
                             </div>
                             <div className="flex justify-center items-center">
                              <input type="checkbox" className="" name="attend[]" value="Mon"/>  
                              <p>Mon</p>
                             </div>
                           </form>
                         </td>
                         <td className="">
                             <div className=""><span className="white-bg dark-gray tiny-header block">N/A</span><small>No unlock</small></div>
                         </td>
                         <td className="">9</td>
                         <td className="">09/02/08</td>
                         <td className="">Verified</td>
                        </tr>
                   </tbody>
                  </table>
      </div>
    </div>
  );
};

export default TournamentManagerModal;