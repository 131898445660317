import gql from 'graphql-tag';

export const BOX_SCORE_MUTATION = gql`
  mutation PublicBoxScore(
    $selectYear: String!, 
    $teamId: Int!, 
    $gameId: Int!, 
    $maxResultsPerDivision: Int!,
  ) {
    publicBoxScore(
      selectYear: $selectYear, 
      teamId: $teamId
      gameId: $gameId, 
      maxResultsPerDivision: $maxResultsPerDivision, 
    ) {
      box_score
    }
  }
`;