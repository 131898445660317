import React from 'react';
import { useParams } from 'react-router-dom';
import EventCalendars from '../logic/endpoints/eventCalendars';
import { formatDate } from '../utils/formatDate';

interface RequestDataProps {
  requestDataType: string;
}

interface Event {
  id: number;
  name: string;
  short_name: string;
  dates: string;
  logo_img: string;
  link: string;
  locations: string;
}

const EventCalendar: React.FC<RequestDataProps> = ({ requestDataType }) => {
  const { orgId } = useParams<{ orgId: string }>();
  const requestDataProps: RequestDataProps = { requestDataType: (orgId ?? '0') };

  function isEventsCalendar(props: any): props is { props: { children: Event[] } } {
    return props && props.props && Array.isArray(props.props.children);
  }
  
  const eventsCalendarResult = EventCalendars(requestDataProps);
  const requestEventProps = isEventsCalendar(eventsCalendarResult)
    ? eventsCalendarResult.props.children
    : [];

  return (
    <div className="min-h-screen p-4 flex justify-center">
      <div className="w-full mx-auto px-4">
        <table className="min-w-full text-white">
          <thead>
            <tr className="bg-gray-800">
              <th className="px-6 py-4 text-center">EVENT</th>
              <th className="px-6 py-4 text-center">DATE</th>
              <th className="px-6 py-4 text-center">NAME</th>
              <th className="px-6 py-4 text-center">LOCATION</th>
            </tr>
          </thead>
          <tbody>
            {requestEventProps.map((event: Event) => (
              <tr key={event.id}>
                <td className="px-6 py-4 text-center">
                  <a
                    href={event.link}
                    className="flex items-center justify-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={event.logo_img}
                      alt={event.name}
                      className="w-20 h-20 mr-4" // Increased by 30%
                    />
                  </a>
                </td>
                <td className="px-6 py-4 text-center">
                  <a
                    href={event.link}
                    className="flex items-center justify-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatDate(event.dates)}
                  </a>
                </td>
                <td className="px-6 py-4 text-center">
                  <a
                    href={event.link}
                    className="flex items-center justify-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event.name}
                  </a>
                </td>
                <td className="px-6 py-4 text-center">
                  <a
                    href={event.link}
                    className="flex items-center justify-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event.locations}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventCalendar;