import React, { useState, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';

interface LiveSearchProps {
  searchData: any[];
  pageType: string;
  getPlaceholder: string;
}

interface EventData {
  id: string;
  name: string;
  [key: string]: any;
}

const LiveSearch: React.FC<LiveSearchProps> = ({ searchData, pageType, getPlaceholder }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState<EventData[]>([]);

  // Ensure rawEventData is an array and filter out null values
  const eventData: EventData[] = Array.isArray(searchData) ? searchData.filter(event => event !== null) : [];

  // Filter and sort event data based on search term
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);

    const lowercasedSearchTerm = term.toLowerCase();

    const filtered = eventData.filter((event: EventData) =>
      event.name && event.name.toLowerCase().includes(lowercasedSearchTerm)
    );

    // Sort the filtered results by id in descending order
    const sorted = filtered
      .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
      .slice(0, 10);

    setFilteredData(sorted);
  };

  return (
    <div className="w-full p-4">
      <input
        type="text"
        value={searchTerm}
        onChange={handleChange}
        placeholder={getPlaceholder}
        className="w-full p-2 border border-gray-300 rounded-md text-black"
      />
      {searchTerm && filteredData.length > 0 ? (
        <ul className="w-full mt-4 text-black">
          {filteredData.map(event => (
            <li key={event.id} className="mb-1">
              <Link
                to={`/${pageType}/${event.id}`}
                className="bg-white block p-2 rounded-md border border-gray-300 hover:bg-gray-100 text-black hover:text-blue-500"
              >
                {event.name}
              </Link>
            </li>
          ))}
        </ul>
      ) : searchTerm && filteredData.length === 0 ? (
        <p className="text-red-500 text-lg font-bold text-center mt-4 bg-white block p-2 rounded-md border border-gray-300">No results found</p>
      ) : null}
    </div>
  );
};

export default LiveSearch;