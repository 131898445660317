import { siteUrl } from './siteUrl';
export const customVariables = (type: string, args: any[]): string => {
  switch(type){
    case 'env-type':
      // Assuming args is an array and we're checking the first item
//       if (args.length > 0 && args[0].site && args[0].site.includes('dev.')) {
//         return 'http://dev.statplatform.sportspassports.com:3001/api/v1/spp-data-hub';
//       }
      return ''; // Return empty string if the condition is not met

    case 'db-alias':
      return 'wp_54ab678738_g365';
    case 'spp-club-logo':
      return `${siteUrl(window.location.href, 'getSiteUrl')}/wp-content/uploads/org-logos/`;
    case 'spp-club-logo-placeholder':
      return `${siteUrl(window.location.href, 'getSiteUrl')}/wp-content/uploads/org-logos/g365_blank-placeholder_400x300.png`;
    default:
      return '';
  }
};