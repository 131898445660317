import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { decompressSync, strFromU8 } from 'fflate';
import client from '../../apolloClient';
import { PLAYER_DIRECTORY_MUTATION } from '../queries/playerDirectoryQuery';

interface RequestDataProps {
  requestDataType: string;
}

const PlayerDirectory: React.FC<RequestDataProps> = ({ requestDataType }) => {
  const [playerData, setPlayerDirectory] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const token = localStorage.getItem('token');
  const condition = "WHERE enabled = 1";

  // Apollo mutation hook
  const [playerDirectory] = useMutation(PLAYER_DIRECTORY_MUTATION, {
    variables: { condition },
    client,
    context: {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await playerDirectory();
        if (data && data.playerDirectory && data.playerDirectory.data) {
          // Extract the base64-encoded compressed data
          const base64Data = data.playerDirectory.data;

          // Convert base64 to Uint8Array
          const compressedData = new Uint8Array(Buffer.from(base64Data, 'base64'));

          // Decompress the data
          const decompressedData = decompressSync(compressedData);
          const decompressedString = strFromU8(decompressedData);

          // Parse the decompressed string into JSON
          const jsonData = JSON.parse(decompressedString);

          // Set the decompressed JSON data to state
          setPlayerDirectory(jsonData);
        }
      } catch (err) {
        setErrorMessage("Error fetching player data.");
        console.error('Fetch error:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [playerDirectory, condition]);

  if (isLoading) return <div>Loading...</div>;
  if (errorMessage) return <div>Error: {errorMessage}</div>;
  return (
    <div>
      {/* Render player data */}
      {playerData && playerData.length > 0 ? (
        playerData
      ) : (
        <div>No player data available.</div>
      )}
    </div>
  );
};

export default PlayerDirectory;