import React from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Routes, 
//   useLocation, 
  useParams } from 'react-router-dom';
import { AuthProvider } from './logic/authContext';
import ProtectedRoute from './logic/protectedRoute';
import SignIn from './pages/signIn';
import SignOut from './pages/signOut';
import TournamentManager from './pages/tournamentManager';
import UpcomingEvent from './pages/upcomingEvent';
import EventCalendar from './pages/eventCalendar';
import TeamStanding from './pages/teamStanding';
import Player from './pages/player';
import DashboardNav from './utils/dashboardNav';
import './App.css';
// import './logic/sentry/instrument'; // Comment it out for now, it caused too many request that need to be fixed.
import { useAuth } from './logic/authContext'; 

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="flex h-screen">
          <ConditionalTopNav />
          <div className="flex-1">
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/" element={<SignIn />} />
              <Route path="/upcoming-event/:orgId" element={<UpcomingEventWrapper />} />
              <Route path="/event-calendar/:orgId" element={<EventCalendarWrapper />} />
              <Route path="/team-standings/:brandId" element={<TeamStandingWrapper />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/tournament-manager" element={<TournamentManager />} />
                <Route path="/player" element={<Player />} />
              </Route>
              <Route path="/signout" element={<SignOut />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

const ConditionalTopNav: React.FC = () => {
  const { user } = useAuth();
//   const location = useLocation();
  // List of paths where you don't want to show DashboardNav
//   const excludedPaths = ['/signin', '/'];

  // Check if the current path is excluded or user is not authenticated
//   if (excludedPaths.some(path => location.pathname.startsWith(path)) || !user) {
//     return null;
//   }
  if(user){
    return <DashboardNav />;  
  }else{
    return null;
  }
};

// Wrapper component to handle URL params and pass them as props
const UpcomingEventWrapper: React.FC = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const requestDataType = parseInt(orgId ?? '0', 10);

  if (isNaN(requestDataType)) {
    return <div>Error: Invalid orgId</div>;
  }

  return <UpcomingEvent requestDataType={requestDataType} />;
};

const EventCalendarWrapper: React.FC = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const requestDataType = parseInt(orgId ?? '0', 10);

  if (isNaN(requestDataType)) {
    return <div>Error: Invalid orgId</div>;
  }

  return <EventCalendar requestDataType={requestDataType.toString()} />;
};
                                   
const TeamStandingWrapper: React.FC = () => {
  const { brandId } = useParams<{ brandId: string }>(); // Make brandId a string
  const requestDataType = brandId || '3191'; // Default to 'grassroots-g365' if empty or null

  return <TeamStanding requestDataType={requestDataType} />;
};

export default App;