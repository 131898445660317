import gql from 'graphql-tag';

export const TEAM_STANDING_MUTATION = gql`
  mutation PublicTeamStanding(
    $brand: Int!, 
    $select_year: String!, 
    $level_of_play: String!, 
    $division: String!, 
    $win_loss_percent_cutoff: Float!, 
    $show_girls: Boolean!, 
    $max_results_per_division: Int!
  ) {
    publicTeamStanding(
      brand: $brand, 
      select_year: $select_year, 
      level_of_play: $level_of_play, 
      division: $division, 
      win_loss_percent_cutoff: $win_loss_percent_cutoff, 
      show_girls: $show_girls, 
      max_results_per_division: $max_results_per_division
    ) {
      org_id
      team_id
      level_of_play
      division
      full_team_name
      org_logo
      total_wins
      total_losses
      win_percentage
      ppg
      opp_ppg
    }
  }
`;